<template>
  <!-- Notation - Variables calculées -->
  <div class="variable-calculated-subview">
    <div class="tabs-material-subtitle no-padding-bottom">
      <Container>
        <h2>Variables calculées</h2>
      </Container>
    </div>

    <Section>
      <Container>
        <template v-if="calculatedVariables.length > 0">
          <template v-for="(variable, index) in calculatedVariables" :key="index">
            <div class="margin-bottom">
              <div class="display-flex no-margin">
                <div class="padding-right">
                  <Checkbox :disabled="!isEditable" v-model="calculatedVariablesSelected[index].selected" :key="componentKey"
                    :id="index.toString()" :items="[{
                      label: '',
                      value: true,
                    }]" />
                </div>
                <div class="flex-grow ">
                  <div class="margin-bottom-quarter">
                    <h4 class="no-margin">{{ variable?.calcul?.designation }}</h4>
                    <div v-if="variable?.cible">Cible: {{ variable?.cible?.valeur }}</div>
                    <div><small><b>Variable(s) source:</b> {{ variable.vsources.join(', ') }}</small></div>
                  </div>

                  <template v-if="calculatedVariablesSelected[index].selected && variable.paramssource.length > 0">
                    <div class="margin-bottom-quarter"><b>Paramètre(s)</b></div>
                    <div class="grid no-margin">
                      <template v-for="(paramssource, j) in variable.paramssource" :key="j">
                        <div class="tmp-col">
                          <SelectExtended v-if="paramssource.valeurs" :id="`param-${index}`"
                            v-model="calculatedVariablesSelected[index].paramssource[j].value"
                            :globalError="!calculatedVariablesSelected[index].paramssource[j].value"
                            required
                            :label="paramssource.designation" optionKey="value" optionValue="label"
                            :items="paramssource.valeurs ? JSON.parse(paramssource.valeurs) : null" />
                          <Input v-else v-model="calculatedVariablesSelected[index].paramssource[j].value"
                            :id="`param-${index}`" type="number" :label="paramssource.designation"
                            required
                            :globalError="!calculatedVariablesSelected[index].paramssource[j].value" />
                        </div>
                      </template>
                    </div>
                  </template>

                </div>
              </div>
            </div>
          </template>

        </template>

        <template v-else>
          Aucun élément dans cette liste.
        </template>
      </Container>
    </Section>
  </div>

  <div class="view-footer" v-if="calculatedVariables.length > 0">
    <div class="view-actions view-actions--left">
      <Btn text="Annuler" :to="{ name: 'essaiCalendar', params: { id: $route.params.id } }" />
      <Btn @click="handleSubmit()" text="Enregistrer" color="primary" />
    </div>
  </div>

</template>

<script>

import Btn from '@/components/base/Btn.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Checkbox from '@/components/form/Checkbox.vue'
// import Modal from '@/components/layout/Modal.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import Input from '@/components/form/Input.vue'

export default {
  name: 'VariableCalculatedSubview',

  components: {
    SelectExtended,
    Container,
    Section,
    Btn,
    Input,
    Checkbox,
  },

  props: {
    pageTitle: {
      type: String,
    },
    essai: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
    task: {
      type: Object,
    },
  },

  data() {
    return {
      fiche: [],
      calculatedVariables: [],
      calculatedVariablesSelected: [],
      componentKey: 0,
    }
  },

  async mounted() {
    await this.getFiche()
  },

  methods: {

    /**
     * Get notation sheet
     */
    async getFiche() {
      this.emitter.emit('open-loader')
      const response = await this.fetchService.get(`essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation`)
      this.fiche = response.data.pop()
      await this.getCalculatedVariables()
      this.emitter.emit('close-loader')
    },

    /**
     * Get calculated variables fetched from the api
     */
    async getCalculatedVariables() {
      const variableResponse = await this.fetchService.get(`essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation/${this.fiche.id}/variable_calculee?limit=0`)
      let localCalculatedVariables = []

      if (variableResponse.data.length > 0) {
        localCalculatedVariables = variableResponse.data.sort(
          (a, b) => a.calcul.designation.localeCompare(b.calcul.designation),
        )

        // eslint-disable-next-line
        for (const cv of localCalculatedVariables) {
          console.log('cv', cv)
          const vsourcesArray = []
          cv.calcul.variables.forEach((vsource) => {
            const index = vsourcesArray.findIndex((item) => (item.id === vsource.id))
            if (index === -1) {
              vsourcesArray.push(vsource.designation)
            }
          })

          // eslint-disable-next-line
          cv.vsources = vsourcesArray
          // eslint-disable-next-line
          const paramsResponse = await this.fetchService.get(`dictionnaire/variable_calculee/${cv.calcul.id}/parametre`)
          // eslint-disable-next-line
          cv.paramssource = []

          // eslint-disable-next-line
          for (const param of paramsResponse.data) {
            // eslint-disable-next-line
            param.value = null

            if (cv.parametres.length > 0) {
              const source = cv.parametres.find((item) => item.id === param.id)
              if (source && source.valeurs === null) {
                param.value = source.pivot.valeur
              }
              if (source && source.valeurs !== null) {
                const valeurs = JSON.parse(source.valeurs)
                const toAdd = valeurs.find((item) => parseInt(item.value, 10) === parseInt(source.pivot.valeur, 10))
                param.value = { key: toAdd.value, value: toAdd.label }
              }
            }

            cv.paramssource.push(param)
          }

          // eslint-disable-next-line
          cv.displayparams = false

          // eslint-disable-next-line
          cv.paramsoadd = []

          // eslint-disable-next-line
          cv.selected = cv.id ? true : false
        }

        this.calculatedVariables = localCalculatedVariables
        this.calculatedVariablesSelected = localCalculatedVariables
      }
    },

    async handleSubmit() {
      this.emitter.emit('open-loader')

      console.log('this.calculatedVariablesSelected', this.calculatedVariablesSelected)

      if (this.calculatedVariablesSelected.filter((cv) => cv.id != null && !cv.selected).length > 0) {
        await this.fetchService
          .delete(`essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation/${this.fiche.id}/variable_calculee`,
            this.calculatedVariablesSelected.filter((cv) => cv.id).map((cv) => ({
              id: cv.id,
            })))
      }

      if (
        this.calculatedVariablesSelected.filter((cv) => cv.selected && cv.id !== null).length > 0
      ) {
        this.fetchService
          .put(`essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation/${this.fiche.id}/variable_calculee`,
            this.calculatedVariablesSelected.filter((cv) => cv.selected && cv.id != null)
              .map((cv) => ({
                id: cv.id,
                calcul_id: cv.calcul.id,
                cible_id: cv?.cible?.id,
                parametres: cv.paramssource.map((param) => ({
                  id: param.id,
                  valeur: param.valeurs ? parseInt(param?.value?.key, 10) : param.value,
                })),
              })))
          .then(async () => {
            await this.getFiche()
            this.emitter.emit('alert', {
              type: 'success',
              content: 'Les variables calculées ont bien été ajoutées.',
            })
          }, (errorResponse) => {
            this.emitter.emit('close-loader')
            if (errorResponse.data?.fields) {
              this.emitter.emit('alert', {
                type: 'error',
                content: 'Une variable calculée et mal paramétré.',
              })
            } else {
              this.emitter.emit('alert', {
                type: 'error',
                content: 'Une erreur est survenue.',
              })
            }
          })
      }
      if (this.calculatedVariablesSelected.filter((cv) => cv.selected && cv.id === null).length > 0) {
        this.fetchService
          .post(`essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation/${this.fiche.id}/variable_calculee`,
            this.calculatedVariablesSelected.filter((cv) => cv.selected && !cv.id)
              .map((cv) => ({
                calcul_id: cv.calcul.id,
                cible_id: cv?.cible?.id,
                parametres: cv.paramssource.map((param) => ({
                  id: param.id,
                  valeur: param.valeurs ? parseInt(param?.value?.key, 10) : param.value,
                })),
              })))
          .then(async () => {
            await this.getFiche()
            this.emitter.emit('alert', {
              type: 'success',
              content: 'Les variables calculées ont bien été ajoutées.',
            })
          }, (errorResponse) => {
            this.emitter.emit('close-loader')
            if (errorResponse.data?.fields) {
              this.emitter.emit('alert', {
                type: 'error',
                content: 'Une variable calculée et mal paramétré.',
              })
            } else {
              this.emitter.emit('alert', {
                type: 'error',
                content: 'Une erreur est survenue.',
              })
            }
          })
      } else {
        await this.getFiche()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.display-flex {
  display: flex;
}

.isError {
  color: red
}

.padding {
  padding: 1rem 0;
}

.padding-right {
  padding-right: 2rem;
}
</style>
